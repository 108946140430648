<template>
    <v-card flat>
        <v-card-title class="headline justify-center"
            >Изменения проекта
        </v-card-title>
        <v-card-text class="title text--primary text-center">
            В данном разделе Вы сможете отслеживать изменения, над которыми в
            данный момент ведётся работа.<br />
            <br />
            <v-card outlined>
                <todo-item
                    v-for="todo in todos.slice().reverse()"
                    :key="todo.id"
                    :chip="todo.chip"
                    :description="todo.description"
                    :title="todo.title"
                />
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
import TodoItem from "@/components/About/TodoItem";

export default {
    name: "todo",
    components: { TodoItem },
    data: () => ({
        todos: [
            {
                id: 1,
                chip: { text: "ВНЕДРЕНО", color: "success" },
                title: {
                    text: "Добавить поиск в полных списках объёмов/упаковок",
                    status: "уже внедрено!",
                    color: "success"
                },
                description: {
                    text:
                        "Вам нужно поменять цену у препарата Х. Каждый раз в полном\n" +
                        "                списке листать до него, серьёзно?.."
                }
            },
            {
                id: 2,
                chip: { text: "ИСПРАВЛЕНО", color: "success" },
                title: {
                    text: "Общая стоимость в стационарном распределении",
                    status: "исправлено!",
                    color: "success"
                },
                description: {
                    text: `При изменении количества случаев в распределении схем по стационарам пересчитывается всё, кроме общей цены. <br /> Для перерасчёта общей цены нужно перейти в другой тип стационара, либо нажать кнопку перераспределения случаев. <br /> Должно перерасчитываться мгновенно.`
                }
            },
            {
                id: 3,
                chip: { text: "НОВОЕ", color: "warning" },
                title: {
                    text: `Развить функционал каналов финансирования: <br /> Добавить возможность сначала выбирать схемы, применяемые в дневных и круглосуточных стационарах, <br />а уже затем выбирать схемы, применяемые амбулаторно`,
                    status: "обсуждается... (2021)",
                    color: "warning"
                },
                description: {
                    text: `Если сначала выбрать схемы, которые точно будут применяться стационарно, то появляется возможность узнать необходимый бюджет для амбулаторного лечения`
                }
            },
            {
                id: 4,
                chip: { text: "РАЗРАБОТКА", color: "success" },
                title: {
                    text: `Изменить комбинации конкретных препаратов (Наименование + МНН + лек. форма) на МНН + лек. форма`,
                    status: "в разработке... (2021)",
                    color: "warning"
                },
                description: {
                    text: `Мнения некоторых коллег сходятся по данному вопросу: не стоит указывать конкретные рыночные наименования упаковок, оставить только МНН + лек. форму`
                }
            },
            {
                id: 5,
                chip: { text: "РАЗРАБОТКА", color: "success" },
                title: {
                    text:
                        "Изменение количества пациентов в уже созданном проекте",
                    status: "первоочерёдно.",
                    color: "error"
                },
                description: {
                    text: `Вы ответили на все вопросы по нозологиям, распределили проценты, выбрали нужные схемы, но ввели 10000, а не 15000 пациентов...<br /> И снова новый проект..?`
                }
            },
            {
                id: 6,
                chip: { text: "РАЗРАБОТКА", color: "success" },
                title: {
                    text: "Переход на старую версию",
                    status: "первоочерёдно.",
                    color: "error"
                },
                description: {
                    text: `Вы хотите использовать систему со старыми клин. реками? Конечно, все Ваши проекты сохранятся.`
                }
            }
        ]
    })
};
</script>
