<template>
    <v-card flat>
        <v-card-title class="headline justify-center"
            >Изменения проекта
        </v-card-title>
        <v-card-text class="title text--primary text-center">
            В данном разделе Вы сможете видеть изменения внутри системы. <br />
            <div class="mt-4">
                <v-chip color="warning" draggable>ИСПРАВЛЕНИЕ</v-chip>
                27.05.2021: <br />
                Обнаружена ошибка в расчёте тарифа КСГ. <br />
                Расчёт тарифов КСГ полностью обновлён в соответствии со
                стандартами 2021 года.<br />
                Для обновления КСГ до тарифов 2021 года достаточно сбросить
                значения по умолчанию или поменять регион. <br />
            </div>
            <div class="mt-4">
                <v-chip color="warning" draggable>ОБНОВЛЕНИЕ</v-chip>
                26.03.2021:<br />
                Полностью обновлены данные (в соответствии с КР 2020 года и КСГ
                2021);<br />
                Система полностью адаптирована под разные мониторы;<br />
                Исправлено множество недочётов (<a
                    @click="changes2021Expanded = !changes2021Expanded"
                >
                    {{ changes2021Expanded ? "свернуть" : "полный список" }}
                </a>
                );
                <v-expand-transition>
                    <ul
                        v-show="changes2021Expanded"
                        class="subtitle-2 text-justify mx-auto"
                        style="max-width: 800px;"
                    >
                        <li>
                            Грамматические правки. Если Вы заметили, что где-то
                            есть грамматические ошибки - Вы можете отправить
                            информацию на site@soft-medica.ru
                        </li>
                        <li>Отстортированы нозологии (по коду МКБ)</li>
                        <li>Отсортированы стадии в вопросах по нозологиям</li>
                        <li>
                            Добавлена возможность поиска схем при ручном вводе
                            данных
                        </li>
                        <li>
                            Добавлена возможность поиска по результатам расчётов
                        </li>
                        <li>
                            Добавлена возможность поиска по списку амбулаторных
                            схем
                        </li>
                        <li>
                            Добавлена возможность поиска схем при ручном вводе
                            данных
                        </li>
                        <li>
                            Изменён интерфейс распределения стационаров
                        </li>
                        <li>
                            Изменён интерфейс ручного ввода данных
                        </li>
                        <li>
                            Добавлен МНН в отображении результатов в виде
                            упаковок ЛП
                        </li>
                    </ul>
                </v-expand-transition>
            </div>
            <div class="mt-4">
                <v-chip color="success" draggable>РЕЛИЗ</v-chip> 23.12.2020:
                <br />Запущен проект. <br />
                <br />
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "changelog",
    data: () => ({
        changes2021Expanded: false
    })
};
</script>
