<template>
    <v-dialog fullscreen v-model="dialog" hide-overlay>
        <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-1" outlined v-bind="attrs" v-on="on">
                О программе
            </v-btn>
        </template>
        <v-card>
            <v-toolbar dark color="primary">
                <v-spacer />
                <v-card-title>
                    О программе
                </v-card-title>
                <v-spacer />
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container>
                <v-card>
                    <v-tabs v-model="tab" grow show-arrows>
                        <v-tab key="changelog">
                            Изменения проекта
                        </v-tab>
                        <v-tab key="todo">
                            Над чем мы работаем
                        </v-tab>
                        <v-tab key="buglog">
                            Сообщить об ошибке
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item key="changelog">
                            <changelog />
                        </v-tab-item>
                        <v-tab-item key="todo">
                            <todo />
                        </v-tab-item>
                        <v-tab-item key="buglog">
                            <v-card flat>
                                <v-card-title class="text-wrap justify-center">
                                    Если у Вас возникнут проблемы или Вы
                                    обнаружите какую-то ошибку в системе,
                                    отправьте максимально полную, по
                                    возможности, информацию на почту:
                                    <br />
                                </v-card-title>
                                <v-card-title
                                    class="text-wrap justify-center headline"
                                >
                                    <a href="mailto:site@soft-medica.ru">
                                        site@soft-medica.ru
                                    </a>
                                </v-card-title>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
import Changelog from "@/components/About/Changelog.vue";
import Todo from "@/components/About/Todo.vue";

export default {
    name: "about",
    components: {
        Todo,
        Changelog
    },
    data: () => ({
        tab: null,
        dialog: false
    })
};
</script>
