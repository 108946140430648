<template>
    <v-list-item two-line>
        <v-list-item-content>
            <v-list-item-title class="text-wrap align-center">
                <v-chip :color="chip.color" draggable small label>
                    {{ chip.text }}
                </v-chip>
                <span class="ml-2" v-html="title.text" />
                =>
                <b :class="titleStatusColor">{{ title.status }}</b>
            </v-list-item-title>
            <v-list-item-subtitle
                class="text-wrap"
                v-html="`<span>&#8220;${description.text}&#8221;</span>`"
            >
            </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>
<script>
export default {
    name: "todo-item",
    props: {
        chip: {},
        title: {},
        description: {}
    },
    computed: {
        titleStatusColor() {
            return `${this.title.color}--text`;
        }
    }
};
</script>
